import { Box, Button, Checkbox, FormControlLabel, FormGroup, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { addDays, addMonths, addWeeks, format } from 'date-fns';
import { FC, useEffect, useState } from 'react';
import FormDialog from '../../../../shared/components/FormDialog';

import { NoAccounts } from '@mui/icons-material';
import { useSnackbar } from 'notistack';
import {
   ProfileModel,
   useSetSuspensionMutation,
} from '../../../../shared/utilities/__generated__/graphql';
import SuspensionReasonsSelect from '../../../Reports/components/SuspensionReasonsSelect';

const SuspendButton: FC<{ user: ProfileModel; onChange?: () => void }> = ({ user, onChange }) => {
   const { enqueueSnackbar } = useSnackbar();
   const [isOpen, setIsOpen] = useState(false);

   const [reason, setReason] = useState('');

   const [permanent, setPermanent] = useState(false);
   const [until, setUntil] = useState<Date | null>(addMonths(new Date(), 12));

   const [setSuspensionMutation, { error, loading, data }] = useSetSuspensionMutation({
      variables: {
         suspensionInput: {
            until: until?.toISOString(),
            permanent,
            userId: user.id!,
            reason,
         },
      },
   });

   useEffect(() => {
      if (data) {
         enqueueSnackbar(
            `Account suspended ${permanent ? 'permanently' : `until ${format(until!, 'PPPP')}'`}!`,
            { variant: 'success' },
         );

         onChange && onChange();
      }
   }, [data]);

   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setPermanent(event.target.checked);
   };

   const handleSubmit = async () => {
      await setSuspensionMutation();
      setIsOpen(false);
   };

   return (
      <>
         <Button startIcon={<NoAccounts />} variant='outlined' onClick={() => setIsOpen(true)}>
            Suspend
         </Button>
         <FormDialog
            title='Suspend user'
            open={isOpen}
            onSubmit={handleSubmit}
            onClose={() => setIsOpen(false)}
            loading={loading}
            saveButtonProps={{
               color: 'error',
               icon: NoAccounts,
               children: 'Suspend',
               loading: loading,
            }}
         >
            <FormGroup>
               <FormControlLabel
                  control={<Checkbox checked={permanent} onChange={handleChange} />}
                  label='Permanent'
               />
               <DatePicker
                  label='End of suspension'
                  value={until}
                  disabled={permanent}
                  onChange={(newValue) => {
                     setUntil(newValue);
                     if (!newValue) {
                        setPermanent(false);
                     }
                  }}
                  renderInput={(params) => <TextField sx={{ mt: 2 }} {...params} />}
               />

               <SuspensionReasonsSelect onChange={(e) => setReason(e)} />

               <TextField
                  multiline
                  rows={3}
                  label='Reason'
                  onChange={(e) => setReason(e.target.value)}
                  value={reason}
               />

               {/* button to set 1 week */}
               <Box mt={2}>
                  {[
                     {
                        until: addMonths(new Date(), 12),
                        text: '1 year',
                     },
                     {
                        until: addMonths(new Date(), 3),
                        text: '3 months',
                     },
                     {
                        until: addMonths(new Date(), 1),
                        text: '1 month',
                     },
                     {
                        until: addWeeks(new Date(), 1),
                        text: '1 week',
                     },
                     {
                        until: addDays(new Date(), 1),
                        text: '1 day',
                     },
                  ]
                     .reverse()
                     .map(({ until, text }) => (
                        <Button
                           key={text}
                           variant='outlined'
                           onClick={() => {
                              setUntil(until);
                              setPermanent(false);
                           }}
                           sx={{ mt: 1, mr: 1 }}
                           disabled={permanent}
                        >
                           {text}
                        </Button>
                     ))}
               </Box>

               {error && <div>Something went wrong: {error.message}</div>}
            </FormGroup>
         </FormDialog>
      </>
   );
};

export default SuspendButton;
