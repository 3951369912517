import {
   ApolloClient,
   ApolloLink,
   ApolloProvider,
   createHttpLink,
   DefaultOptions,
   InMemoryCache,
   split,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';

import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { Provider as ReduxProvider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import RoutesComponent from './features/Routing/components/Routes/Routes';
import Layout from './shared/components/Layout';
import QuerifyStateProvider from './shared/components/QuerifyStateProvider';
import store from './shared/store';
import { getCFTokenFromCookie } from './shared/utilities/helpers';
import theme from './shared/utilities/theme';

const httpLink = createHttpLink({
   uri: process.env.REACT_APP_GRAPHQL_ENDPOINT,
});

// const wsEndpoint = process.env.REACT_APP_GRAPHQL_ENDPOINT?.replace('http', 'ws') ?? '';

// Websocket with graphql-ws
const wsLink = new GraphQLWsLink(
   createClient({
      url: process.env.REACT_APP_GRAPHQL_WS_ENDPOINT!,
      connectionParams: () => ({
         Authorization: `Bearer ${getCFTokenFromCookie()}`,
      }),
   }),
);

const authLink = setContext((_, { headers }) => {
   // get the authentication token from local storage if it exists
   const token = getCFTokenFromCookie();

   // return the headers to the context so httpLink can read them
   return {
      headers: {
         ...headers,
         authorization: token ? `Bearer ${token}` : '',
      },
   };
});

const defaultOptions: DefaultOptions = {
   watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
   },
   query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
   },
};
// Split Link to route queries/mutations to httpLink and subscriptions to wsLink
const splitLink = split(
   ({ query }) => {
      const definition = getMainDefinition(query);
      return definition.kind === 'OperationDefinition' && definition.operation === 'subscription';
   },
   wsLink, // Use wsLink for subscriptions
   httpLink, // Use httpLink for queries and mutations
);

// Apollo Client Setup
const client = new ApolloClient({
   cache: new InMemoryCache({
      addTypename: false,
   }),
   link: ApolloLink.from([authLink, splitLink]),
   defaultOptions,
});

function App() {
   return (
      <ThemeProvider theme={theme}>
         <LocalizationProvider dateAdapter={AdapterDateFns}>
            <CssBaseline />
            <ApolloProvider client={client}>
               <Router>
                  <ReduxProvider store={store}>
                     <Layout>
                        <SnackbarProvider
                           autoHideDuration={4000}
                           anchorOrigin={{
                              vertical: 'top',
                              horizontal: 'right',
                           }}
                        >
                           <QuerifyStateProvider>
                              <ConfirmProvider>
                                 <RoutesComponent />
                              </ConfirmProvider>
                           </QuerifyStateProvider>
                        </SnackbarProvider>
                     </Layout>
                  </ReduxProvider>
               </Router>
            </ApolloProvider>
         </LocalizationProvider>
      </ThemeProvider>
   );
}

export default App;
