// other
import {
   CheckBox,
   DashboardOutlined,
   FlagCircle,
   Group,
   LightbulbCircle,
   Message,
   PermDataSettingSharp,
   Place,
   QuestionAnswer,
   Security,
   TravelExploreOutlined,
   VerifiedUser,
} from '@mui/icons-material';

import ReceiptLongIcon from '@mui/icons-material/ReceiptLong';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { FC } from 'react';
import Home from '../../../screens/Home';
import {
   ADMIN_APPLICATION_AUDS,
   AMBASSADOR_APPLICATION_AUDS,
   INVESTOR_APPLICATION_AUDS,
} from '../../../shared/utilities/config';
import AegisOverview from '../../Aegis/screens/AegisOverview';
import AuditLogs from '../../AuditLog/AuditLogs';
import WatchMessages from '../../Messages';
import PlacesOverviewSceen from '../../Place/screens/PlacesOverview/PlacesOverview';
import PlaceSuggestions from '../../PlaceSuggestion/screens/PlaceSuggestions';
import AllPrompts from '../../prompts/screens/AllPrompts';
import EditPrompt from '../../prompts/screens/EditPrompt';
import NewPrompt from '../../prompts/screens/NewPrompt';
import RedisConfig from '../../RedisConfig/screens';
import Reports from '../../Reports/screens/Reports';
import ViewReportedChat from '../../Reports/screens/ViewReportedChat';
import TrueUser from '../../TrueUser/screens/TrueUser';
import UserCoverageMapScreen from '../../UserCoverage/screens/UserCoverageMap/UserCoverageMap';
import Users from '../../Users/screens/Users';
import ViewUser from '../../Users/screens/ViewUser';
import WaitListedUsers from '../../Users/screens/WaitListedUsers';

// interface
interface Route {
   key: string;
   title: string;
   path: string;
   enabled: boolean;
   component: FC<any>;
   icon: OverridableComponent<SvgIconTypeMap>;
   inMenu: boolean;
   contentPadding?: boolean;
   auds?: string[];
}
export const routes: Array<Route> = [
   {
      key: 'home-route',
      title: 'Home',
      path: '/',
      enabled: true,
      component: Home,
      icon: DashboardOutlined,
      inMenu: true,
   },
   {
      key: 'audit-logs',
      title: 'View audit logs',
      path: '/audit-logs',
      enabled: true,
      component: AuditLogs,
      icon: CheckBox,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'users-route',
      title: 'Users',
      path: '/users',
      enabled: true,
      component: Users,
      icon: Group,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'wait-listed-users-route',
      title: 'Wait list',
      path: '/wait-list',
      enabled: true,
      component: WaitListedUsers,
      icon: ReceiptLongIcon,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'reports-route',
      title: 'Reports',
      path: '/reports',
      enabled: true,
      component: Reports,
      icon: FlagCircle,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'true-users-route',
      title: 'True users',
      path: '/true-users',
      enabled: true,
      component: TrueUser,
      icon: VerifiedUser,
      inMenu: true,
      auds: AMBASSADOR_APPLICATION_AUDS,
   },
   {
      key: 'place-suggestions-route',
      title: 'Place Suggestions',
      path: '/place-suggestions',
      enabled: true,
      component: PlaceSuggestions,
      icon: LightbulbCircle,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'user-coverage-route',
      title: 'User coverage',
      path: '/user-coverage',
      enabled: true,
      component: UserCoverageMapScreen,
      icon: TravelExploreOutlined,
      inMenu: true,
      contentPadding: false,
      auds: INVESTOR_APPLICATION_AUDS,
   },
   {
      key: 'places-overview-route',
      title: 'Places',
      path: '/places',
      enabled: true,
      component: PlacesOverviewSceen,
      icon: Place,
      inMenu: true,
      contentPadding: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'view-user-route',
      title: 'User',
      path: '/users/:id',
      enabled: true,
      component: ViewUser,
      icon: Group,
      inMenu: false,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'view-reported-chat',
      title: 'View reported chat',
      path: '/reported-chats/:id',
      enabled: true,
      component: ViewReportedChat,
      icon: Group,
      inMenu: false,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'aegis-overview',
      title: 'Aegis',
      path: '/aegis',
      enabled: true,
      component: AegisOverview,
      icon: Security,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'prompt-administration',
      title: 'Prompts',
      path: '/prompts',
      enabled: true,
      component: AllPrompts,
      icon: QuestionAnswer,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'watch-messages',
      title: 'Live Messages',
      path: '/messages',
      enabled: true,
      component: WatchMessages,
      icon: Message,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'edit-prompt',
      title: 'Edit Prompts',
      path: '/prompts/:id',
      enabled: true,
      component: EditPrompt,
      icon: QuestionAnswer,
      inMenu: false,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'new-prompt',
      title: 'New prompt',
      path: '/new-prompt',
      enabled: true,
      component: NewPrompt,
      icon: QuestionAnswer,
      inMenu: false,
      auds: ADMIN_APPLICATION_AUDS,
   },
   {
      key: 'redis-config',
      title: 'Redis Config',
      path: '/redis-config',
      enabled: true,
      component: RedisConfig,
      icon: PermDataSettingSharp,
      inMenu: true,
      auds: ADMIN_APPLICATION_AUDS,
   },
];
