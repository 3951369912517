import SyncIcon from '@mui/icons-material/Sync';
import { Button } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { SnackbarKey, useSnackbar } from 'notistack';
import { FC, useEffect, useRef } from 'react';
import {
   ProfileModel,
   useSyncPurchasesMutation,
} from '../../../../shared/utilities/__generated__/graphql';

const SyncPurchasesButton: FC<{ user: ProfileModel }> = ({ user }) => {
   const notification = useRef<SnackbarKey | undefined>(undefined);
   const { enqueueSnackbar, closeSnackbar } = useSnackbar();
   const confirm = useConfirm();

   const [syncPurchases, { error, loading }] = useSyncPurchasesMutation({
      variables: {
         userId: user.id!,
      },
   });

   const handleSync = async () => {
      await confirm({
         title: 'Sync purchases?',
         description: "This will sync the user's purchase status with RevenueCat",
         confirmationText: 'Sync',
         cancellationText: 'Cancel',
      }).then(async () => {
         closeSnackbar(notification.current);
         notification.current = enqueueSnackbar(`Syncing purchases...`, {
            variant: 'info',
            autoHideDuration: null,
         });

         // Sync
         const res = await syncPurchases();

         if (res.data?.adminSubscriptionStatus) {
            closeSnackbar(notification.current);
            enqueueSnackbar(`Purchases synced successfully`, {
               variant: 'success',
            });
         }
      });
   };

   useEffect(() => {
      if (error && !loading) {
         closeSnackbar(notification.current);
         enqueueSnackbar(`Failed to sync purchases`, {
            variant: 'error',
         });
      }
   }, [error, loading]);

   return (
      <Button startIcon={<SyncIcon />} variant='outlined' onClick={() => handleSync()}>
         Sync Purchases
      </Button>
   );
};

export default SyncPurchasesButton;
