import { Box, Paper, Typography, useTheme } from '@mui/material';
import { FC, ReactElement, useState } from 'react';

interface MessageProps {
   message: string;
   timestamp?: string;
   isOwnMessage?: boolean;
}

const Message: FC<MessageProps> = ({ message }): ReactElement => {
   const theme = useTheme();
   const [isOwnMessage] = useState<boolean>(Math.random() > 0.5);
   const [timestamp] = useState<string>(
      new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
   );

   return (
      <Box
         sx={{
            display: 'flex',
            justifyContent: isOwnMessage ? 'flex-end' : 'flex-start',
            mb: 2,
            mx: 2,
         }}
      >
         <Box
            sx={{
               maxWidth: '70%',
            }}
         >
            <Paper
               elevation={1}
               sx={{
                  padding: '8px 16px 8px 16px',
                  backgroundColor: isOwnMessage
                     ? theme.palette.primary.light
                     : theme.palette.grey[100],
                  borderRadius: '20px',
                  borderTopRightRadius: isOwnMessage ? '4px' : '20px',
                  borderTopLeftRadius: isOwnMessage ? '20px' : '4px',
                  boxShadow: isOwnMessage
                     ? '0px 1px 2px rgba(0, 0, 0, 0.1), 0px 2px 4px rgba(0, 0, 0, 0.1)'
                     : '0px 1px 2px rgba(0, 0, 0, 0.1)',
               }}
            >
               <Typography
                  variant='body1'
                  sx={{
                     color: isOwnMessage ? 'white' : 'inherit',
                  }}
               >
                  {message}
               </Typography>
            </Paper>
            <Typography
               variant='caption'
               sx={{
                  display: 'block',
                  mt: 0.5,
                  ml: 1,
                  color: theme.palette.text.secondary,
               }}
            >
               {timestamp}
            </Typography>
         </Box>
      </Box>
   );
};

export default Message;
