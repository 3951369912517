import { useEffect, useState } from 'react';
import { useSubscribeToAllChatsSubscription } from '../../shared/utilities/__generated__/graphql';
import Message from './Message/Message';

interface Message {
   id: number;
   content: string;
}

const WatchMessages = () => {
   const { data, loading, error } = useSubscribeToAllChatsSubscription({});
   const [messages, setMessages] = useState<Message[]>([]);

   // Constants for layout
   const MESSAGE_HEIGHT = 100; // Adjust based on your Message component height
   const MESSAGE_WIDTH = 250; // Adjust based on your Message component width
   const MESSAGE_GAP = 10; // Gap between messages

   useEffect(() => {
      if (data?.subscribeToAllChats?.message?.content) {
         const newMessage = {
            content: data.subscribeToAllChats.message.content,
            id: Date.now(), // Add unique identifier
         };
         setMessages((prev) => [...prev, newMessage].slice(-100));
      }

      setTimeout(() => {
         // Scroll to bottom
         window.scrollTo({
            behavior: 'smooth',
            top: document.body.scrollHeight,
         });
      });
   }, [data?.subscribeToAllChats?.message?.content]);

   const getMessagePosition = (index: number) => {
      const containerHeight = window.innerHeight * 0.85; // 85vh as per original
      const messagesPerColumn = Math.floor(containerHeight / (MESSAGE_HEIGHT + MESSAGE_GAP));

      const column = Math.floor(index / messagesPerColumn);
      const row = index % messagesPerColumn;

      return {
         left: `${column * (MESSAGE_WIDTH + MESSAGE_GAP)}px`,
         top: `${row * (MESSAGE_HEIGHT + MESSAGE_GAP)}px`,
      };
   };

   if (loading) return <div className='flex items-center justify-center h-screen'>Loading...</div>;
   if (error) return <div className='text-red-500'>Error: {error.message}</div>;

   return (
      <div className='relative h-[85vh] w-full overflow-auto'>
         {messages.map((msg, index) => {
            const { top, left } = getMessagePosition(index);
            return (
               <div
                  key={msg.id}
                  className='absolute animate-[fadeIn_0.5s_ease-in-out]'
                  style={{
                     top,
                     left,
                     transition: 'all 0.3s ease-in-out',
                  }}
               >
                  <Message message={msg.content} />
               </div>
            );
         })}
      </div>
   );
};

export default WatchMessages;
